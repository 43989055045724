if (document.body.classList.contains("page-template-page-online-form") || document.body.classList.contains("page-template-page-contact") || document.body.classList.contains("page-template-page-online-form-card")) {
  const cellOptions = [
    {
      classification: "pipeline",
      imgSrc:
        "/wp-content/themes/main/dist/img/captcha/frame-12.png"
    },
    {
      classification: "pipeline",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-13.png"
    },
    {
      classification: "other",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-2.png"
    },
    {
      classification: "other",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-4.png"
    },
    {
      classification: "pipeline",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-15.png"
    },
    {
      classification: "other",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-16.png"
    },
    {
      classification: "other",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-18.png"
    },
    {
      classification: "other",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-8.png"
    },
    {
      classification: "other",
      imgSrc:
      "/wp-content/themes/main/dist/img/captcha/frame-9.png"
    }
  ];
  
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex]
      ];
    }
    return array;
  }
  
  let shuffledOptions = [];
  let humanPercent = 10;
  
  function createCaptcha() {
    shuffledOptions = shuffle(cellOptions);
  
    const captcha = document.querySelector(".js-captcha");
  
    let innerHTML = "";
    for (var option of shuffledOptions) {
      innerHTML += `
    <div class="captcha-cell" data-classification="${option.classification}">
      <img src="${option.imgSrc}"  />
    </div>
    `;
    }
  
    captcha.innerHTML = innerHTML;
  
    Array.from(document.querySelectorAll(".captcha-cell")).forEach((el) => {
      el.addEventListener("click", () => {
        el.classList.toggle("selected");
      });
    });
  }
  createCaptcha();
  
  const submitButton = document.querySelector(".btn-submit");
  submitButton.addEventListener("click", () => {
    const numberOfPipeline = shuffledOptions.filter(
      (opt) => opt.classification == "pipeline"
    ).length;
    const selectedElements = Array.from(
      document.querySelectorAll(".captcha-cell.selected")
    );
  
    const countMismatch = numberOfPipeline != selectedElements.length;
  
    const fillMdaField = document.querySelector('[name="mda_captcha"]');

    const timestamp = document.querySelector('[name="mda_form_date"]').value;

    const captchaOk = document.querySelector('.captcha__ok');

    const invalidSelections = selectedElements.filter(
      (opt) => opt.dataset.classification != "pipeline"
    );
  
    if (invalidSelections.length || countMismatch) {
      createCaptcha();
      console.log("error");
      document.body.classList.add("error");
    } else {
      fillMdaField.value = Math.floor((timestamp*3)+234/2);
      captchaOk.style.visibility = "visible";
      captchaOk.style.opacity = "1";
    }
  });
}